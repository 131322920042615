import './Project.css'

export default function Project(props: {
    name: string,
    desc: string,
    img: string,
    imgSet: {
        url: string,
        width: number,
        screenWidth: number | undefined
    }[]
}) {
    const { name, desc, img, imgSet } = props;
    return <div className={"project"}>
        <div className={"text"}>
            <h3 className={"name"}>{name}</h3>
            <p className={"desc"}>{desc}</p>
        </div>
        <div className={"img-container"}>
            <img srcSet={
                imgSet.map(i => i.url + " " + i.width + "w").join(", ")
            } sizes={
                imgSet.map(i =>
                    (i.screenWidth ? (`(max-width: ${i.screenWidth})`) : "") + i.width + "px"
                ).join(", ")
            } src={img} alt={name} width={"100%"} height={"auto"}/>
        </div>
    </div>
}