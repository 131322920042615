import './IntroSection.css'
const Java = require("../../icons/java.svg").ReactComponent;
const MongoDB = require("../../icons/mongodb.svg").ReactComponent;
const Mysql = require("../../icons/mysql.svg").ReactComponent;
const ReactIcon = require("../../icons/react.svg").ReactComponent;
const SpringBoot = require("../../icons/spring.svg").ReactComponent;
const TypeScript = require("../../icons/typescript.svg").ReactComponent;
const Svelte = require("../../icons/svelte.svg").ReactComponent;

export default function IntroSection() {
    return <div className={"intro-section"}>
        <div className={"text"}>
            <div className={"titles"}>
                <p className={"header-text hello"}>Hello, my name is</p>
                <h1>Martin Balcar</h1>
                <h2 className={"header-text"}>I am a <b>full-stack web developer</b></h2>
            </div>
            <div className={"desc"}>
                These are some of the technologies I'm working with
                <div className={"technologies"}>
                    <Java className={"icon"}/>
                    <SpringBoot className={"icon"}/>
                    <TypeScript className={"icon"}/>
                    <ReactIcon className={"icon"}/>
                    <Mysql className={"icon"}/>
                    <MongoDB className={"icon"}/>
                    <Svelte className={"icon"}/>
                </div>
            </div>
        </div>
    </div>
}