import './Footer.css'
import {Github, Linkedin} from "react-bootstrap-icons";

export default function Footer() {
    
    return <footer>
        <div className={"contact"}>
            <h2>Let's talk</h2>
            <a href={"mailto:raixo@raixo.cz"}>Send me an email to raixo@raixo.cz</a>
        </div>
        <div className={"other"}>
            <a href={"https://github.com/raixocz"} target={"_blank"} rel="noreferrer" aria-label={"GitHub - Martin Balcar"}><Github/></a>
            <a href={"https://www.linkedin.com/in/martin-balcar-741528274/"} target={"_blank"} rel="noreferrer" aria-label={"LinkedIn - Martin Balcar"}><Linkedin/></a>
        </div>
    </footer>;
}