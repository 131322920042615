import Project from "./Project";

export default function ProjectList() {
    
    return <>
        <h2 className={"header"}>My projects</h2>
        <Project name={"Linteum"} desc={
            "Linteum project is an interactive educational program focused on teaching the fundamentals of programming through block programming. " +
            "The project targets children aged 10 to 15 and provides them with an intuitive environment to learn the basics of programming. " +
            "Additionally, the Linteum project also offers introductory lessons in encryption, compression, and machine learning."
            } img={"/asset/linteum_1300.webp"} imgSet={[
                {
                    url: "/asset/linteum_600.webp",
                    width: 600,
                    screenWidth: 400
                },
                {
                    url: "/asset/linteum_1000.webp",
                    width: 1000,
                    screenWidth: 800
                },
                {
                    url: "/asset/linteum_1300.webp",
                    width: 1300,
                    screenWidth: undefined
                }
        ]}/>
    <Project name={"AI on ISS"} desc={
            "My friend Zdenek Balcar and I were the first people to use AI on the International Space Station. It happened in the Astro PI competition in 2022."
            } img={"/asset/iss_1300.webp"} imgSet={[
                {
                    url: "/asset/iss_600.webp",
                    width: 600,
                    screenWidth: 400
                },
                {
                    url: "/asset/iss_1000.webp",
                    width: 1000,
                    screenWidth: 800
                },
                {
                    url: "/asset/iss_1300.webp",
                    width: 1300,
                    screenWidth: undefined
                }
        ]}/>
    </>
}