import './App.css'
import IntroSection from "./sections/introduction/IntroSection";
import Footer from "./sections/footer/Footer";
import ProjectList from "./sections/project/ProjectList";
import React, {useLayoutEffect} from "react";
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";

const Wrapper = ({children}: { children: any }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

export default function App() {
    return <BrowserRouter>
        <Wrapper>
            <Routes>
                <Route path={"*"} element={<div className={"app"}>
                    <IntroSection/>
                    <ProjectList/>
                    <Footer/>
                </div>}/>
            </Routes>
        </Wrapper>
    </BrowserRouter>
}